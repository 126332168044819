import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['feedback', 'feedbackRadio']
  }

  optionClicked () {
    if (this.hasFeedbackRadioTarget) {
      if (this.feedbackRadioTarget.checked) {
        this.feedbackTarget.disabled = false
      } else {
        this.feedbackTarget.disabled = true
      }
    }
  }
}
