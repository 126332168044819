import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['edit', 'field', 'save']
  }

  switchToEditMode () {
    this.editTarget.classList.add('hidden')
    this.saveTarget.classList.remove('hidden')

    this.fieldTargets.forEach((field) => {
      field.disabled = false
    })
  }
}
