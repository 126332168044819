import { Controller } from '@hotwired/stimulus'
import { initializeSearch } from 'components/help_search'

export default class extends Controller {
  static get targets () {
    return ['results']
  }

  connect () {
    this.searchInitialized = false
  }

  loadSearch () {
    if (this.searchInitialized === false) {
      const search = initializeSearch()

      if (search) {
        search.start()
        this.searchInitialized = true
      }
    }

    this.showResults()
  }

  showResults () {
    this.resultsTarget.classList.remove('hidden')
  }

  hideResults () {
    setTimeout(() => {
      this.resultsTarget.classList.add('hidden')
    }, 100)
  }
}
