import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  promptUser (event) {
    const value = window.prompt('Are you sure you want to cancel this? Type \'yes\' to confirm')
    if (value !== 'yes') {
      event.preventDefault()
    }
  }
}
