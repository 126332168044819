import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'

export default class extends Controller {
  static get targets () {
    return ['button', 'spinner']
  }

  connect () {
    const paymentIdentifier = this.element.dataset.paymentIdentifier
    const failed = this.element.dataset.failed

    if (paymentIdentifier && failed === 'false') {
      this.channel = consumer.subscriptions.create(
        {
          channel: 'TrueLayerDataReceivedChannel',
          payment_identifier: paymentIdentifier
        },
        {
          received: this.process
        }
      )
    }
  }

  process () {
    window.location.reload()
  }

  beginPayment () {
    this.buttonTarget.style.display = 'none'
    this.spinnerTarget.classList.remove('hidden')

    const url = this.buttonTarget.dataset.url
    setTimeout(() => {
      window.location.href = url
    }, 4000)
  }
}
