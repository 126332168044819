import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static get targets () {
    return ['template']
  }

  static get values () {
    return {
      target: String
    }
  }

  connect () {
    tippy(this.targetValue, {
      allowHTML: true,
      content: this.templateTarget.innerHTML,
      duration: 0,
      maxWidth: 500,
      placement: 'bottom',
      trigger: 'click'
    })
  }
};
