import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['input']
  }

  static get values () {
    return {
      cents: Boolean,
      euroLocale: Boolean,
      underflowMessage: String,
      overflowMessage: String,
      stepMessage: String
    }
  }

  get underflowMessage () {
    return this.underflowMessageValue || i18n.t('client-errors.messages.underflow')
      .replace(/\{\d*\}/, this.inputTarget.min)
  }

  get overflowMessage () {
    return this.overflowMessageValue || i18n.t('client-errors.messages.overflow')
      .replace(/\{\d*\}/, this.inputTarget.max)
  }

  get minValue () {
    return parseFloat(this.inputTarget.min)
  }

  get maxValue () {
    return parseFloat(this.inputTarget.max)
  }

  get stepValue () {
    return parseInt(this.inputTarget.step)
  }

  connect () {
    if (this.hasInputTarget) {
      const decimalPlaces = this.centsValue ? 2 : 0

      const normal = { decimalPlaces, unformatOnSubmit: true }

      let euro = { }
      if (this.euroLocaleValue) {
        euro = { decimalCharacter: ',', decimalCharacterAlternative: '.', digitGroupSeparator: '.' }
      }
      const settings = { ...normal, ...euro }

      this.autoNumeric = new AutoNumeric(this.inputTarget, settings)

      if (decimalPlaces > 0) {
        this.inputTarget.inputMode = null
      }
    }
  }

  validate (event) {
    if (this.minValue > this.autoNumeric.getNumber()) {
      event.currentTarget.setCustomValidity(this.underflowMessage)
    } else if (this.autoNumeric.getNumber() > this.maxValue) {
      event.currentTarget.setCustomValidity(this.overflowMessage)
    } else if (this.stepValue > 0 && this.autoNumeric.getNumber() % this.stepValue !== 0) {
      event.currentTarget.setCustomValidity(this.stepMessageValue)
    } else {
      event.currentTarget.setCustomValidity('')
    }
  }
}
