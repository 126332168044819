import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['input']
  }

  toggleInput () {
    this.inputTargets.forEach((target, index) => {
      target.disabled = !target.disabled
      target.required = !target.disabled
    })
  }
}
