import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['page', 'errorMessage']
  }

  connect () {
    this.currentIndex = 0
  }

  get currentError () {
    return this.errorMessageTargets[this.currentIndex]
  }

  showErrorMessage () {
    this.currentError.classList.remove('hidden')
  }

  hideErrorMessage () {
    this.currentError.classList.add('hidden')
  }

  showPrevious () {
    this.hideCurrentPage()
    this.currentIndex -= 1
    this.showCurrentPage()
  }

  showNext () {
    if (this.questionAnswered()) {
      this.hideErrorMessage()
      this.showNextQuestion()
    } else {
      this.showErrorMessage()
    }
  }

  questionAnswered () {
    return this.pageTargets[this.currentIndex].querySelector('input:checked')
  }

  showNextQuestion () {
    this.hideCurrentPage()
    this.currentIndex += 1
    this.showCurrentPage()
  }

  hideCurrentPage () {
    this.pageTargets[this.currentIndex].classList.add('hidden')
  }

  showCurrentPage () {
    this.pageTargets[this.currentIndex].classList.remove('hidden')
  }
}
