import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['search', 'houseNumber', 'street', 'city', 'region', 'regionSelect', 'postalCode', 'country']
  }

  static get values () {
    return {
      key: String
    }
  }

  static get outlets () {
    return ['region']
  }

  connect () {
    this.injectCDN()
  }

  injectCDN () {
    if (window.pcaLoading) {
      return
    }
    window.pcaLoading = true

    const CSS_URL = 'https://services.postcodeanywhere.co.uk/css/address-3.70.css'
    const CDN_URL = 'https://services.postcodeanywhere.co.uk/js/address-3.70.js'

    const l = document.createElement('link')
    l.rel = 'stylesheet'
    l.href = CSS_URL
    document.head.appendChild(l)

    const s = document.createElement('script')
    s.src = CDN_URL
    s.onerror = this.onPcaError.bind(this)

    document.head.appendChild(s)
  }

  onPcaError () {
    this.pcaFailed = true
    window.pcaLoading = false
  }

  loadLoqate () {
    if (this.pcaFailed) {
      return
    }

    window.pca.ready(() => {
      this.setupFields()
    })
  }

  setupFields () {
    const populateMode = window.pca.fieldMode.POPULATE
    const fields = [
      { element: this.searchTarget, field: '', mode: window.pca.fieldMode.SEARCH },
      {
        element: this.houseNumberTarget,
        field: '{BuildingName}{, {BuildingNumber}}',
        mode: populateMode
      },
      { element: this.streetTarget, field: 'Street', mode: populateMode },
      { element: this.cityTarget, field: 'City', mode: populateMode },
      { element: this.regionTarget, field: 'ProvinceName', mode: populateMode },
      { element: this.postalCodeTarget, field: 'PostalCode', mode: populateMode },
      {
        element: this.countryTarget,
        field: 'CountryName',
        mode: populateMode | window.pca.fieldMode.COUNTRY
      }
    ]

    const control = new window.pca.Address(fields, { key: this.keyValue })
    control.listen('populate', this.populateCountry.bind(this))
  }

  populateCountry (address) {
    const newCountry = this.countryTarget.querySelector(`option[data-alpha3="${address.CountryIso3}"]`)
    this.countryTarget.value = newCountry.value
    this.regionOutlet.determineInput(address.CountryIso3)

    if (address.CountryIso3 === 'USA') {
      this.regionSelectTarget.value = address.ProvinceName
    } else {
      this.regionTarget.value = address.ProvinceName
    }
  }
};
