import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['input', 'uniqueAnswer', 'nonUniqueAnswer']
  }

  static get values () {
    return {
      message: String
    }
  }

  validateAnyChecked () {
    if (this.isAnyChecked()) {
      this.assignMessage('')
    } else {
      this.assignMessage(this.messageValue)
    }
  }

  uniqueOptionClicked () {
    if (this.hasUniqueAnswerTarget) {
      if (this.uniqueAnswerTarget.checked) {
        this.nonUniqueAnswerTargets.forEach((element) => {
          element.checked = false
        })
      }
    }
  }

  nonUniqueOptionClicked () {
    if (this.hasUniqueAnswerTarget) {
      if (this.uniqueAnswerTarget.checked) {
        this.uniqueAnswerTarget.checked = false
      }
    }
  }

  isAnyChecked () {
    return this.inputTargets.some((element) => {
      return element.checked
    })
  }

  assignMessage (message) {
    this.inputTargets.forEach((element) => {
      element.setCustomValidity(message)
    })
  }
}
