import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect () {
    this.initializeAllSelects()
  }

  initializeAllSelects () {
    if (process.env.RAILS_ENV !== 'test') {
      this.element.querySelectorAll('select').forEach((element) => {
        this.initializeSelect(element)
      })
    }
  }

  initializeSelect (element) {
    const disabled = element.disabled || element.closest('fieldset')?.disabled || false
    const showSearch = element.dataset.showSearch || false
    element.dataset.editorTarget = 'field'

    return new SlimSelect({
      select: element,
      settings: {
        showSearch,
        disabled
      }
    })
  }
}
