import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['income', 'assets', 'commitments', 'worth', 'percentage']
  }

  get formatter () {
    return new Intl.NumberFormat(document.documentElement.lang, {
      style: 'currency',
      currency: 'EUR'
    })
  }

  get incomeValue () {
    return this._formatValue(this.incomeTarget.value || '0')
  }

  get assetsValue () {
    return this._formatValue(this.assetsTarget.value || '0')
  }

  get commitmentsValue () {
    return this._formatValue(this.commitmentsTarget.value || '0')
  }

  calculate () {
    const result = this.incomeValue + this.assetsValue - this.commitmentsValue

    this.worthTarget.innerHTML = this.formatter.format(result)
    this.percentageTarget.innerHTML = this.formatter.format(result / 10)
  }

  _formatValue (textInput) {
    return parseInt(
      this._normalizeValue(textInput), 10
    )
  }

  _normalizeValue (textInput) {
    return parseInt(
      textInput.replaceAll(',', '').replaceAll('.', '')
    )
  }
}
