import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values () {
    return {
      content: String,
      message: String
    }
  }

  updateContentValue (event) {
    this.contentValue = event.currentTarget.value
  }

  validateEqualTo (event) {
    const value = event.currentTarget.value

    if (this.contentValue === value) {
      event.currentTarget.setCustomValidity('')
    } else {
      event.currentTarget.setCustomValidity(this.messageValue)
    }
  }

  validateNotEqualTo (event) {
    const value = event.currentTarget.value

    if (this.caseInsensitiveEqualTo(value)) {
      event.currentTarget.setCustomValidity(this.messageValue)
    } else {
      if (event.currentTarget.checkValidity()) {
        event.currentTarget.setCustomValidity('')
      }
    }
  }

  caseInsensitiveEqualTo (value) {
    if (value) {
      return this.contentValue.toLowerCase().trim() === value.toLowerCase().trim()
    }
  }
}
